/* Plugins */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

/* Global variables */
import { theme } from '../styles/theme';

/* Components */
import {
  SEO, Container, Heading,
} from '../components/components';
import { ContactBox } from '../components/Communication';

/* Styles */
import { StyledContactWrapper } from '../components/Communication/ContactBox/ContactBox.styled';

/* Messages */
import * as messages from '../messages/kontakt.messages';

const query = graphql`
  query {
    companyLogo: file(name: { eq: "logo-gray" }) {
      publicURL
    }
    phoneIcon: file(name: { eq: "phone-icon" }) {
      publicURL
    }
    emailIcon: file(name: { eq: "email-icon" }) {
      publicURL
    }
    whatsAppIcon: file(name: { eq: "whatsapp-icon" }) {
      publicURL
    }
  }
`;

const Contact = () => {
  const data = useStaticQuery(query);
  const companyDetails = `${theme.contacts.NIP} \n ${theme.contacts.address}, \n ${theme.contacts.postalCode}, \n ${theme.contacts.province}`;

  const itemList = [
    {
      alt: 'baner-bunny',
      path: data.companyLogo.publicURL,
      title: `${theme.contacts.companyName}`,
      description: companyDetails,
      className: 'company',
    },
    {
      alt: 'phone-icon',
      path: data.phoneIcon.publicURL,
      title: `${messages.callText}`,
      link: `${theme.contacts.phone}`,
      target: `tel:${theme.contacts.phone}`,
    },
    {
      alt: 'email-icon',
      path: data.emailIcon.publicURL,
      title: `${messages.writeText}`,
      link: `${theme.contacts.email}`,
      link2: `${theme.contacts.email2}`,
      target: `mailto:${theme.contacts.email}`,
      target2: `mailto:${theme.contacts.email2}`,
    },
    {
      alt: 'WhatsApp-icon',
      path: data.whatsAppIcon.publicURL,
      title: `${messages.whatsApp}`,
      link: `${messages.whatsAppText}`,
      target: 'https://api.whatsapp.com/send/?phone=48516964323&text&app_absent=0',
    },
  ];

  return (
    <>
      <SEO title={messages.mainHeader} />
      <Container subPage>
        <Heading headingLevel="h1" title={messages.mainHeader} centered />
        <StyledContactWrapper>
          {itemList.map((item) => (
            <ContactBox
              iconAlt={item.alt}
              iconSrc={item.path}
              title={item.title}
              description={item.description}
              linkText={item.link}
              linkTarget={item.target}
              secondLinkText={item.link2}
              secondLinkTarget={item.target2}
              className={item.className}
            />
          ))}
        </StyledContactWrapper>
        {/* <Section>
          <ContactForm />
        </Section> */}
      </Container>
    </>
  );
};

export default Contact;
